import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';

type ToastMessage = {
  id: string;
  severity: 'error' | 'success' | 'info' | 'warn';
  summary: string;
  detail: string;
  life?: number;
  sticky?: boolean;
};
interface AlertState {
  messages: ToastMessage[];
}

const initialState: AlertState = {
  messages: [],
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Omit<ToastMessage, 'id'>>) => {
      const id = nanoid();
      state.messages.push({
        id,
        ...action.payload,
        life: action.payload.life || 5000,
      });
    },
    removeToast: (state, action: PayloadAction<{ id: string }>) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload.id
      );
    },
  },
});

export const { addToast, removeToast } = alertSlice.actions;
export default alertSlice.reducer;
