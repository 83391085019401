import { GUID, IPageResponse } from '../../types/common.types';
import {
  IClassesResponse,
  IClassesParams,
  IClassDetailResponse,
} from './types';
import TAG_TYPES from '@/utils/constants/tagTypes';
import { baseApi } from '../baseApi';
import { IFollowUpNote } from '@/services/students/types';
import { getDummyFollowUpNotes } from '@/services/students/follow-up-note';
import { getDummyFollowUpNotesForClasses } from '@/services/classes/follow-up-notes-class';

export const classesApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    classesOverview: builder.query<
      IPageResponse<IClassesResponse>,
      IClassesParams
    >({
      query: (classesParams) => ({
        url: `/classes/overview`,
        headers: {
          CampusID: classesParams.campusID,
        },
        params: { ...classesParams, campusID: undefined },
      }),
      providesTags: [TAG_TYPES.CLASSES],
    }),
    classDetail: builder.query<
      IClassDetailResponse,
      { CampusID?: string; classID?: string }
    >({
      query: ({ CampusID, classID }) => ({
        url: `/classes/${classID}`,
        headers: {
          CampusID,
        },
      }),
      providesTags: [TAG_TYPES.CLASSES],
    }),
    updateClass: builder.mutation<unknown, IClassDetailResponse>({
      query: ({ campusID, classID, ...updateClassParams }) => ({
        url: `/classes/${classID}`,
        method: 'PUT',
        headers: {
          CampusID: campusID,
        },
        data: updateClassParams,
      }),
      invalidatesTags: (result, error, args) => [
        { type: TAG_TYPES.CLASSES, id: args.classID },
        { type: TAG_TYPES.CLASSES },
        TAG_TYPES.ORGANIZATIONS,
      ],
    }),
    deleteClass: builder.mutation<
      unknown,
      { campusID: string; classID: string }
    >({
      query: ({ campusID, classID }) => ({
        url: `/classes/${classID}`,
        method: 'DELETE',
        headers: {
          CampusID: campusID,
        },
      }),
      invalidatesTags: (result, error, args) => [
        { type: TAG_TYPES.CLASSES, id: args.classID },
        { type: TAG_TYPES.CLASSES },
      ],
    }),
    followUpNotesByClassID: builder.query<
      IPageResponse<IFollowUpNote>,
      { campusID?: GUID; classID: GUID }
    >({
      /* query: ({ campusID, studentID }) => ({
        url: `follow-up-note/${classID}`,
        headers: {
          CampusID: campusID,
        },
      }), */

      queryFn: async () => {
        const data = getDummyFollowUpNotesForClasses();
        return {
          data,
        };
      },
      providesTags: (result, error, args) => [
        { type: TAG_TYPES.TRACKING_NOTE, id: args.campusID },
      ],
    }),
  }),
});

export const {
  useClassesOverviewQuery,
  useClassDetailQuery,
  useLazyClassDetailQuery,
  useUpdateClassMutation,
  useDeleteClassMutation,
  useFollowUpNotesByClassIDQuery,
} = classesApi;
