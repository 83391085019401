import { IPageParams, IPageResponse } from '../../types/common.types';
import {
  CampusesResponse,
  ICampusOverviewRequest,
  ICampusOverviewResponse,
  ICreateCampusesRequest,
  IDropdownOptions,
  IOrgCampusDepartmentResquest,
  IOrgCampusGradeClassesRequest,
  IOrgCampusGradesRequest,
  IOrganization,
  IOrganizationsResponse,
} from './types';
import TAG_TYPES from '@/utils/constants/tagTypes';
import { baseApi } from '../baseApi';
import { ICampus } from '@/pages/Organizations/Setup/types';
import { IClassDetailResponse } from '../classes/types';
import { getCampusId } from '@/utils/helpers/campus';

interface IPageParamsWithSearch extends IPageParams {
  q?: string;
}

export const organizationsApi = baseApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    organizations: builder.query<
      IPageResponse<IOrganizationsResponse>,
      Partial<IPageParams>
    >({
      query: (params) => ({
        url: `/organizations`,
        headers: {
          isPublic: true,
        },
        params,
      }),
      providesTags: [TAG_TYPES.ORGANIZATIONS],
    }),
    organizationsEnriched: builder.query<
      IPageResponse<IOrganizationsResponse>,
      IPageParamsWithSearch
    >({
      query: (params) => ({
        url: `/organizations/admin/enriched`,
        params,
      }),
      providesTags: [TAG_TYPES.ORGANIZATIONS],
    }),
    createOrganization: builder.mutation<
      { id: string; name: string },
      { name: string }
    >({
      query: (params) => ({
        url: '/organizations/admin',
        data: { name: params.name },
        method: 'POST',
      }),
      invalidatesTags: [
        TAG_TYPES.ORGANIZATIONS,
        TAG_TYPES.CAMPUSES,
        TAG_TYPES.DEPARTMENTS,
        TAG_TYPES.GRADES,
      ],
    }),
    createCampusesForOrganization: builder.mutation<
      ICampus[],
      ICreateCampusesRequest
    >({
      query: (params) => ({
        url: `/organizations/admin/${params.organizationId}/campuses`,
        data: {
          campuses: params.campuses,
        },
        method: 'POST',
      }),
      invalidatesTags: [TAG_TYPES.ORGANIZATIONS],
    }),
    orgCampusDepartment: builder.query<
      IPageResponse<IDropdownOptions>,
      IOrgCampusDepartmentResquest
    >({
      query: ({ campusID }) => {
        return {
          url: `/organizations/campuses/departments`,
          headers: {
            CampusID: campusID,
          },
        };
      },
      providesTags: (result, error, args) => [
        TAG_TYPES.ORGANIZATIONS,
        { type: TAG_TYPES.CAMPUSES, id: args.campusID },
      ],
    }),
    orgCampusGrades: builder.query<
      IPageResponse<IDropdownOptions>,
      IOrgCampusGradesRequest
    >({
      query: ({ campusID = getCampusId(), departmentID }) => {
        return {
          url: `/organizations/campuses/departments/${departmentID}/grades`,
          headers: {
            CampusID: campusID,
          },
        };
      },
      providesTags: (result, error, args) => [
        TAG_TYPES.ORGANIZATIONS,
        { type: TAG_TYPES.CAMPUSES, id: args.campusID },
        { type: TAG_TYPES.DEPARTMENTS, id: args.departmentID },
      ],
    }),
    orgCampusGradeClasses: builder.query<
      IPageResponse<IDropdownOptions>,
      IOrgCampusGradeClassesRequest
    >({
      query: ({ campusID, departmentID, gradeID }) => {
        return {
          url: `/organizations/campuses/departments/${departmentID}/grades/${gradeID}/classes`,
          headers: {
            CampusID: campusID,
          },
        };
      },
      providesTags: (result, error, args) => [
        TAG_TYPES.ORGANIZATIONS,
        { type: TAG_TYPES.CAMPUSES, id: args.campusID },
        { type: TAG_TYPES.DEPARTMENTS, id: args.departmentID },
        { type: TAG_TYPES.GRADES, id: args.gradeID },
      ],
    }),
    orgCampusesOverview: builder.query<
      IPageResponse<ICampusOverviewResponse>,
      ICampusOverviewRequest
    >({
      query: ({ campusID, ...params }) => {
        return {
          url: `/organizations/campuses/overview`,
          params,
          headers: {
            CampusID: campusID,
          },
        };
      },
      providesTags: (result, error, args) => [
        TAG_TYPES.ORGANIZATIONS,
        { type: TAG_TYPES.CAMPUSES, id: args.campusID },
      ],
    }),
    organizationById: builder.query<IOrganization, { organization_id: string }>(
      {
        query: (params) => ({
          url: `/organizations/admin/${params.organization_id}`,
        }),
        providesTags: (result, error, args) => [
          TAG_TYPES.ORGANIZATON,
          { type: TAG_TYPES.ORGANIZATON, id: args.organization_id },
        ],
      }
    ),
    organizationCampuses: builder.query<
      CampusesResponse,
      { organization_id: string }
    >({
      query: (params) => ({
        url: `/organizations/admin/${params.organization_id}/campuses`,
      }),
      providesTags: (result, error, args) => {
        return [{ type: TAG_TYPES.CAMPUSES, id: args.organization_id }];
      },
    }),
    updateOrganization: builder.mutation<
      IOrganization,
      { name: string; organization_id: string }
    >({
      query: (params) => ({
        url: `/organizations/admin/${params.organization_id}`,
        data: { name: params.name },
        method: 'PUT',
      }),
      invalidatesTags: [
        TAG_TYPES.ORGANIZATIONS,
        TAG_TYPES.CAMPUSES,
        TAG_TYPES.DEPARTMENTS,
        TAG_TYPES.GRADES,
      ],
    }),
    updateCampusesForOrganization: builder.mutation<
      ICampus[],
      ICreateCampusesRequest
    >({
      query: (params) => ({
        url: `/organizations/admin/${params.organizationId}/campuses`,
        data: {
          campuses: params.campuses,
        },
        method: 'PUT',
      }),
      invalidatesTags: [TAG_TYPES.ORGANIZATIONS],
    }),
    orgAddClassToCampus: builder.mutation<unknown, IClassDetailResponse[]>({
      query: (params) => {
        const [classParams] = params;
        return {
          url: `/organizations/campuses/classes`,
          method: 'POST',
          data: params,
          headers: {
            CampusID: classParams.campusID,
          },
        };
      },
      invalidatesTags: [TAG_TYPES.CLASSES],
    }),
  }),
});

export const {
  useOrganizationsQuery,
  useCreateOrganizationMutation,
  useCreateCampusesForOrganizationMutation,
  useOrgCampusDepartmentQuery,
  useOrgCampusGradesQuery,
  useOrgCampusGradeClassesQuery,
  useOrgCampusesOverviewQuery,
  useOrganizationsEnrichedQuery,
  useOrganizationCampusesQuery,
  useLazyOrganizationCampusesQuery,
  useOrganizationByIdQuery,
  useLazyOrganizationByIdQuery,
  useUpdateCampusesForOrganizationMutation,
  useUpdateOrganizationMutation,
  useOrgAddClassToCampusMutation,
} = organizationsApi;
