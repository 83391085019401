import { useCallback, useEffect, useState } from 'react';
import { useLazyGetStudentByIdQuery } from '@/services/students';
import { useLazyClassDetailQuery } from '@/services/classes';
import { useEmployeeLessonsQuery } from '@/services/lessons';
import { ILesson } from '@/services/lessons/types';
import { hasPermission } from '@/utils/helpers/userPermission';
import { Operations } from '@/utils/enums/Operations';

interface UseNormalizedLessonsProps {
  entityID?: string;
  platform: 'student' | 'classes';
}

export const useNormalizedLessons = ({
  entityID,
  platform,
}: UseNormalizedLessonsProps) => {
  const [normalizedLessons, setNormalizedLessons] = useState<
    { id: string; name: string }[]
  >([]);

  const [getStudentByID] = useLazyGetStudentByIdQuery();
  const [getClassDetail] = useLazyClassDetailQuery();
  const { data: lessonData } = useEmployeeLessonsQuery({
    page: 1,
    perPage: 1000,
  });

  const formatCourses = useCallback(
    (courses: ILesson[]): { id: string; name: string }[] =>
      courses.map((course) => ({
        id: course.id,
        name: `${course.grade.department?.name} - ${course.grade.name}.Sınıf - ${course.name}`,
      })),
    []
  );

  const fetchClassLessons = useCallback(
    (classID: string) => {
      return getClassDetail({ classID })
        .unwrap()
        .then((classData) => {
          const { departmentID, gradeID } = classData;
          const filteredCourses = lessonData?.items?.filter(
            (course) =>
              course.grade.department?.id === departmentID &&
              course.grade.id === gradeID
          );
          setNormalizedLessons(formatCourses(filteredCourses || []));
        })
        .catch(() => {
          setNormalizedLessons([]);
        });
    },
    [getClassDetail, lessonData, formatCourses]
  );

  useEffect(() => {
    if (
      !lessonData?.items ||
      !entityID ||
      !hasPermission(Operations.CreateLessonTrackingNote)
    ) {
      setNormalizedLessons([]);
      return;
    }

    const handlePlatformLessons = () => {
      if (platform === 'classes') {
        fetchClassLessons(entityID);
      } else if (platform === 'student') {
        getStudentByID({ studentID: entityID })
          .unwrap()
          .then((studentData) => fetchClassLessons(studentData.classID))
          .catch(() => setNormalizedLessons([]));
      }
    };

    handlePlatformLessons();
  }, [entityID, platform, lessonData, fetchClassLessons, getStudentByID]);

  return normalizedLessons;
};
