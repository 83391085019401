import { getAccessToken } from '@/services/axios-instance';
import { parseAuthToken } from '@/utils/helpers/token';
// eslint-disable-next-line import/no-cycle
import { initUserPermissions } from './userPermission';

export const hasMultipleCampuses = (): boolean => {
  const token = getAccessToken();
  if (!token) return false;

  const { managementRoles, roles } = parseAuthToken(token);

  if (managementRoles.length > 0) {
    return true;
  }

  return roles.length > 1;
};

export const onSelectCampus = (campusId: string) => {
  localStorage.setItem('E12_SELECTED_CAMPUS', campusId);
  window.dispatchEvent(new Event('campusIDChanged'));
  initUserPermissions(); // TODO: It will be removed after we put campusId inside the token
};

export const getCampusId = () =>
  localStorage.getItem('E12_SELECTED_CAMPUS') || undefined;

export const removeCampusId = () => {
  localStorage.removeItem('E12_SELECTED_CAMPUS');
};

export const getOrganizationId = (): string => {
  const token = getAccessToken();
  const { organizationID } = parseAuthToken(token as string);
  return organizationID || '';
};

export const getStrongestRole = (): number => {
  const parsedAuthToken = parseAuthToken(getAccessToken());
  const isE12Admin = Boolean(parsedAuthToken.managementRoles.length > 0);

  if (!isE12Admin) {
    const campusID = getCampusId();
    const campusRoles = parsedAuthToken.roles.filter(
      (role) => role.campusID === campusID
    );

    if (campusRoles.length > 0) {
      return Math.min(...campusRoles.map((role) => Math.min(...role.values)));
    }
  }
  return Math.min(...parsedAuthToken.managementRoles);
};
