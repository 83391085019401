import * as yup from 'yup';
import { ICreateTrackingNoteClassesRequest } from '@/services/trackingNote/types';

const trackingNoteClassFormValidations: yup.ObjectSchema<ICreateTrackingNoteClassesRequest> =
  yup.object().shape({
    lessonID: yup.string().required('Ders zorunludur'),
    classID: yup.string().required('Sınıf zorunludur'),
    campusID: yup.string().required('Kampüs zorunludur'),
    departmentID: yup.string().required('Birim zorunludur'),
    gradeID: yup.string().required('Sınıf zorunludur'),
    title: yup
      .string()
      .required('Başlık zorunludur')
      .max(255, 'Başlık 255 karakterden uzun olmamalıdır'),
    description: yup
      .string()
      .required('Açıklama zorunludur')
      .max(2000, 'Açıklama 2000 karakterden uzun olmamalıdır'),
    parentVisibility: yup
      .boolean()
      .default(false)
      .test(
        'at-least-one-student',
        'İki seçenekten en az biri seçilmelidir',
        function (value, context) {
          const { studentVisibility } = context.parent;
          return value || studentVisibility;
        }
      ),
    studentVisibility: yup
      .boolean()
      .default(false)
      .test(
        'at-least-one-parent',
        'İki seçenekten en az biri seçilmelidir',
        function (value, context) {
          const { parentVisibility } = context.parent;
          return value || parentVisibility;
        }
      ),
    students: yup.array().min(1, 'En az bir öğrenci seçilmelidir'),
  });

export default trackingNoteClassFormValidations;
