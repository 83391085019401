import { ALL_CAMPUSES_SUFFIX, Roles } from '../constants/roles';
import { Operations } from '../enums/Operations';
import { UIOperations } from '../enums/UIOperations';
import { Nullable } from '../globalTypes';
import { parseAuthToken } from './token';
// eslint-disable-next-line import/no-cycle
import { getCampusId } from './campus';
import { getAccessToken } from '@/services/axios-instance';
import { navigateToSelectCampus } from './navigate';

export const USER_PERMISSIONS = new Set<Operations | UIOperations>();

export const getRolesByCampusID = (): {
  managementRoles: Nullable<number[]>;
  campusRoles: Nullable<number[]>;
} => {
  const token = getAccessToken();
  const campusID = getCampusId();

  if (!campusID) {
    navigateToSelectCampus();
    return {
      managementRoles: [],
      campusRoles: [],
    };
  }

  const { roles, managementRoles } = parseAuthToken(token as string);

  const campusRoles = roles.find(
    (roleEntry) => roleEntry.campusID === campusID
  );

  return {
    managementRoles,
    campusRoles: campusRoles ? campusRoles.values : null,
  };
};

export const initUserPermissions = (): void => {
  // We need to reset USER_PERMISSIONS Set object on every campus changes
  USER_PERMISSIONS.clear();

  const { managementRoles, campusRoles } = getRolesByCampusID();

  if (!managementRoles?.length && !campusRoles?.length) return;

  // Combine operations for all roles
  const combinedRoles = [...(campusRoles ?? []), ...(managementRoles ?? [])];

  combinedRoles.forEach((role) => {
    const permissions = Roles[role.toString()]?.permissions;
    if (permissions) {
      permissions.forEach((perm) => {
        // If perm has "_all-campuses" suffix, we need to add pure permission without "_all-campuses"
        if (perm.includes(ALL_CAMPUSES_SUFFIX)) {
          const withoutAllCampusesSuffix = perm.replace(
            ALL_CAMPUSES_SUFFIX,
            ''
          );
          USER_PERMISSIONS.add(withoutAllCampusesSuffix as Operations);
        }
        USER_PERMISSIONS.add(perm);
      });
    }
  });

  // eslint-disable-next-line no-console
  // console.log('UserPerms:', USER_PERMISSIONS);
};

export const hasPermission = (
  operation: Operations | UIOperations | (Operations | UIOperations)[]
): boolean => {
  // array of operations
  if (Array.isArray(operation)) {
    return operation.every((op) => USER_PERMISSIONS.has(op));
  }

  // single operation
  return USER_PERMISSIONS.has(operation);
};
