import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { RootState } from '@/store';
import { removeToast } from '@/store/alert';

const CustomToast = () => {
  const toastRef = useRef<Toast>(null);
  const dispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.alert.messages);
  const lastMessageCount = useRef(0);

  useEffect(() => {
    if (messages.length > lastMessageCount.current) {
      messages.slice(lastMessageCount.current).forEach((message) => {
        toastRef.current?.show(message);
        setTimeout(() => {
          dispatch(removeToast({ id: message.id }));
        }, message.life);
      });
      lastMessageCount.current = messages.length;
    }
  }, [messages, dispatch]);

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toastRef} />
    </>
  );
};

export default CustomToast;
