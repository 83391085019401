import { Checkbox, CheckboxProps } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

interface IProps<T extends FieldValues> extends Omit<CheckboxProps, 'checked'> {
  control: Control<T>;
  label: string;
  name: Path<T>;
  wrapperClassName?: string;
}

const ControlledCheckbox = <T extends FieldValues>({
  control,
  label,
  name,
  wrapperClassName,
  ...inputProps
}: IProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, formState }) => (
        <span
          className={classNames(
            'flex items-center space-x-2',
            wrapperClassName
          )}
        >
          {label && (
            <label
              htmlFor={label}
              className={classNames({ 'p-error': formState.errors.checked })}
            >
              {label}
            </label>
          )}
          <Checkbox
            {...field}
            className={classNames({ 'p-invalid mr-1': fieldState.error })}
            onChange={(e) => field.onChange(e.checked)}
            checked={field.value}
            {...inputProps}
          />
          {fieldState.error ? (
            <small className="p-error">
              {fieldState.error?.message as string}
            </small>
          ) : (
            <small className="p-error">&nbsp;</small>
          )}
        </span>
      )}
    />
  );
};

export default ControlledCheckbox;
