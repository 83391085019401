import {
  MiddlewareAPI,
  PayloadAction,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { addToast } from '@/store/alert';

export const rtkQueryErrorLogger =
  (api: MiddlewareAPI) =>
  (next: any) =>
  (
    action: PayloadAction<{
      message: string;
      validations: { message: string }[];
    }>
  ) => {
    if (isRejectedWithValue(action)) {
      if (
        action.payload?.validations?.some((validation) =>
          Boolean(validation.message)
        )
      ) {
        action.payload?.validations?.forEach((validation) => {
          next(
            addToast({
              detail: validation?.message || 'Bir hata oluştu',
              summary: 'Hata',
              severity: 'error',
              life: 8000,
            })
          );
        });
      } else {
        next(
          addToast({
            detail: action.payload?.message || 'Bir hata oluştu',
            summary: 'Hata',
            severity: 'error',
            life: 8000,
          })
        );
      }
    }

    return next(action);
  };
