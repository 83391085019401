import axios from 'axios';

export const getAccessTokenByApi = () =>
  axios.post(
    `${import.meta.env.VITE_API_URL}/authentication/access`,
    {},
    {
      headers: {
        isPublic: true,
      },
      withCredentials: true,
    }
  );
